import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AlignItems,
  Box,
  Flex,
  Icon,
  IconTypes,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';

import { ReservationHeader } from '@gss/components/layout';
import { ETDModal } from '@gss/components/modals';
import {
  getIsDayUseReservation,
  getMakeKeysReservationDetails,
} from '@gss/store/flows/makeKeysFlow/selectors';
import { DateTimeManager } from '@gss/utils/dateTime';
import { makeKeysTestSelector } from '@gss/utils/testSelectors';

import './MakeKeysDetailsHeader.scss';

const TEST_SELECTOR_PREFIX = 'details-header';

export const MakeKeysDetailsHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const [isETDModalOpen, setETDModalOpened] = useState(false);
  const reservationDetails = useSelector(getMakeKeysReservationDetails);
  const isDayUse = useSelector(getIsDayUseReservation);

  const period = isDayUse
    ? DateTimeManager.getFormattedShortDate(reservationDetails?.arrivalDate)
    : `${DateTimeManager.getFormattedShortDate(
        reservationDetails?.arrivalDate
      )} - ${DateTimeManager.getFormattedShortDate(
        reservationDetails?.departureDate
      )}`;

  const formattedEtd = DateTimeManager.getFormattedTime(
    reservationDetails?.etd
  );

  const toggleETDModal = (): void => {
    setETDModalOpened((isOpen) => !isOpen);
  };

  return (
    <>
      {isETDModalOpen && (
        <ETDModal etd={formattedEtd} onClose={toggleETDModal} />
      )}

      <ReservationHeader
        className="make-keys-header-wrapper"
        dataTestSelector={makeKeysTestSelector(TEST_SELECTOR_PREFIX)}
      >
        <Flex alignItems={AlignItems.flexEnd}>
          <Box
            sizeSm={6}
            dataTestSelector={makeKeysTestSelector(
              TEST_SELECTOR_PREFIX,
              'room-details'
            )}
          >
            <Text size={TextSize.xlg} className="spacing-bottom-sm">
              {reservationDetails?.room?.code}
            </Text>
            <Text weight={TextWeight.light}>{t('SHARED.ROOM_NUMBER')}</Text>
          </Box>
          <Box
            sizeSm={6}
            dataTestSelector={makeKeysTestSelector(
              TEST_SELECTOR_PREFIX,
              'stay-details'
            )}
          >
            <Box className="spacing-bottom-sm">
              <Text size={TextSize.xlg}>{period}</Text>
              {isDayUse ? (
                <Flex alignItems={AlignItems.center} className="gap-sm">
                  <Text size={TextSize.lg} weight={TextWeight.light}>
                    {t(
                      'VIEWS.KEY_FLOW.COMPONENTS.RESERVATION_HEADER.EXPECTED_DEPARTURE'
                    )}
                    :
                  </Text>
                  <Text size={TextSize.lg}>{formattedEtd}</Text>
                  <Icon
                    type={IconTypes.exclamationMark}
                    className="day-use-icon"
                    onClick={toggleETDModal}
                  />
                </Flex>
              ) : null}
            </Box>
            <Text weight={TextWeight.light}>{t('SHARED.STAY')}</Text>
          </Box>
        </Flex>
      </ReservationHeader>
    </>
  );
};
